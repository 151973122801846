import * as React from 'react';
import { css } from '@emotion/react';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Button, TextField } from '@mui/material';
import { TextFieldWithLength } from '../components/TextFieldWithLength';
import { MAX_EMAIL_LENGTH, MAX_MESSAGE_LENGTH, MAX_NAME_LENGTH } from '../api/contact';

const ContactPage: React.FC = () => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [answer, setAnswer] = React.useState('');
  const [result, setResult] = React.useState<'success' | 'failure' | undefined>();
  const [sending, setSending] = React.useState(false);
  const num1 = React.useMemo(() => Math.floor(Math.random() * 10), []);
  const num2 = React.useMemo(() => Math.floor(Math.random() * 10), []);

  const correctRef = React.useRef(false);
  const correct = !Boolean(answer) || Number(answer) === num1 + num2;
  correctRef.current = correct;

  const emailError =
    !email || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) ? undefined : 'Enter a valid email address';

  async function handleSend() {
    if (correctRef.current) {
      setResult(undefined);
      setSending(true);
      try {
        const result = await fetch('/api/contact', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            name,
            email,
            message,
          }),
        });
        const data = await result.json();
        setResult(data.result);
      } catch (e) {
        console.error(e);
        setResult('failure');
      }
      setSending(false);
    }
  }

  return (
    <>
      <Header />
      <main
        css={css`
          padding: 3rem;
          flex-grow: 1;
          h1 {
            font-size: 3rem;
            font-weight: 500;
            margin: 0;
          }
        `}
      >
        <h1>Contact Us</h1>
        <div
          css={css`
            width: 100%;
            display: flex;
            flex-direction: row;
            @media only screen and (max-width: 60rem) {
              flex-direction: column;
            }
          `}
        >
          <div
            css={css`
              flex-basis: 100%;
              flex-grow: 1;
              flex-shrink: 1;
              > div {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
              }
            `}
          >
            {result !== 'success' ? (
              <>
                {result === 'failure' && (
                  <>
                    <p>Failed to send message. Please try again later.</p>
                  </>
                )}
                <div>
                  <p>
                    To get in touch with us, please fill in this form or use the <a href="#address">contact details</a>{' '}
                    <span
                      css={css`
                        display: none;
                        @media only screen and (max-width: 60rem) {
                          display: inline;
                        }
                      `}
                    >
                      below
                    </span>
                    <span
                      css={css`
                        display: inline;
                        @media only screen and (max-width: 60rem) {
                          display: none;
                        }
                      `}
                    >
                      on the right
                    </span>
                    .
                  </p>
                  <TextFieldWithLength
                    label="Name"
                    variant="outlined"
                    autoFocus={true}
                    value={name}
                    autoComplete="name"
                    onChange={(event) => setName(event.target.value)}
                    maxLength={MAX_NAME_LENGTH}
                    css={css`
                      width: 100%;
                    `}
                  />
                </div>
                <div>
                  <TextFieldWithLength
                    label="Email"
                    variant="outlined"
                    value={email}
                    autoComplete="email"
                    error={Boolean(emailError)}
                    helperText={emailError}
                    maxLength={MAX_EMAIL_LENGTH}
                    onChange={(event) => setEmail(event.target.value)}
                    css={css`
                      width: 100%;
                    `}
                  />
                </div>
                <div>
                  <TextFieldWithLength
                    label="Message"
                    variant="outlined"
                    multiline
                    minRows={5}
                    value={message}
                    maxLength={MAX_MESSAGE_LENGTH}
                    showLength={true}
                    onChange={(event) => setMessage(event.target.value)}
                    css={css`
                      width: 100%;
                    `}
                  />
                </div>
                <div>
                  <TextField
                    label={`What is ${num1} + ${num2}`}
                    variant="outlined"
                    value={answer}
                    error={!correct}
                    onChange={(event) => setAnswer(event.target.value)}
                    autoComplete={'off'}
                    css={css`
                      width: 10rem;
                    `}
                  />
                </div>
                <p>
                  <Button
                    onClick={handleSend}
                    variant="contained"
                    disabled={!correct || sending || !(name && email && message && answer)}
                  >
                    Send
                  </Button>
                </p>
              </>
            ) : (
              <>
                <p>Message sent.</p>
              </>
            )}
          </div>
          <div
            css={css`
              flex-basis: 25rem;
              flex-grow: 1;
              flex-shrink: 0;
              padding-left: 2rem;
              margin-right: -2rem;
              @media only screen and (max-width: 60rem) {
                padding-left: 0;
                flex-basis: 0;
              }
            `}
          >
            <h2 id="address">Address</h2>
            <p>
              Green Chestnut Limited
              <br />
              36 Dovecote Close
              <br />
              Coventry CV6 1PS
            </p>
            <h2>Phone</h2>
            <p>+44 (0)24 7538 6978<br/>
            +44 (0)7942 292152</p>
            <h2>Email</h2>
            <p
              css={css`
                display: inline-flex;
                flex-direction: row-reverse;
                margin-top: 0;
              `}
            >
              <span>.com</span>
              <span>@greenchestnut</span>
              <span>james</span>
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ContactPage;

export const Head = () => {
  return <title>Contact Us - Green Chestnut</title>;
};
