import { GatsbyFunctionRequest, GatsbyFunctionResponse } from 'gatsby';
import fetch from 'node-fetch';

export const MAX_NAME_LENGTH = 100;
export const MAX_EMAIL_LENGTH = 100;
export const MAX_MESSAGE_LENGTH = 2000;

export async function contact(body: { name?: unknown; email?: unknown; message?: unknown }) {
  const typeErrors = checkTypes(body, [
    { name: 'name', type: 'string' },
    { name: 'email', type: 'string' },
    { name: 'message', type: 'string' },
  ]);
  if (typeErrors.length > 0) {
    return { status: 503, body: { result: 'failure', error: `Fields are incorrect types: ${typeErrors.join(', ')}` } };
  }
  const { name, email, message } = body as { name: string; email: string; message: string };

  if (name.length > MAX_NAME_LENGTH) {
    return { status: 503, body: { result: 'failure', error: `Name longer than ${MAX_NAME_LENGTH} characters.` } };
  }
  if (email.length > MAX_EMAIL_LENGTH) {
    return { status: 503, body: { result: 'failure', error: `Email longer than ${MAX_EMAIL_LENGTH} characters.` } };
  }
  if (message.length > MAX_MESSAGE_LENGTH) {
    return { status: 503, body: { result: 'failure', error: `Message longer than ${MAX_MESSAGE_LENGTH} characters.` } };
  }
  const result = await fetch('https://api.sendinblue.com/v3/smtp/email', {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'api-key': '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',
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      sender: {
        name: 'Green Chestnut Website',
        email: 'noreply@greenchestnut.com',
      },
      to: [
        {
          name: 'James Ots',
          email: 'james@greenchestnut.com',
        },
      ],
      subject: 'Message from website',
      textContent: `From: ${name}
Email: ${email}

${message}
`,
    }),
  });
  const response = await result.json();
  if (response.messageId) {
    return { status: 200, body: { result: 'success' } };
  } else {
    return { status: 503, body: { result: 'failure', error: response } };
  }
}

export function checkTypes(
  body: any,
  types: {
    name: string;
    type: string;
  }[]
) {
  return types
    .map((type) => {
      if (typeof body[type.name] !== type.type) {
        return type.name;
      }
    })
    .filter((value) => value);
}

export default async function handler(req: GatsbyFunctionRequest, res: GatsbyFunctionResponse) {
  const result = await contact(req.body);
  return res.status(result.status).json(result.body);
}
