import { TextFieldProps, TextField } from '@mui/material';
import React from 'react';

export const TextFieldWithLength: React.FC<
  TextFieldProps & {
    maxLength?: number;
    showLength?: boolean;
    value: string;
  }
> = ({ maxLength, showLength, helperText, ...props }) => {
  const lengthText = `${props.value?.length}/${maxLength} characters`;
  let tooLong = false;

  if (maxLength !== undefined && props.value?.length > maxLength) {
    helperText = `Too long. ${lengthText}`;
    tooLong = true;
  } else if (showLength) {
    helperText = lengthText;
  }

  return <TextField {...props} helperText={helperText} error={props.error || tooLong} />;
};
